/* JavaScript used throughout the site, except for the Dashboard pages. jQuery is already included. */
import 'scss/app.scss'
import 'select2'
import 'bootstrap'

$(() => {
  if ($('#submission_form').length) {
    require('form')
  }
  if ($('#program-list').length) {
    require('list')
  }
  if ($('#program-detail').length) {
    require('detail')
  }
})
