// Javascript for the submission form
const $submissionForm = $('#submission_form')

// Select2 for counties select
$('select#id_locations_county').select2({
  width: '100%',
})

// Fix aria-expanded in the accordion headers
$submissionForm.find('.card-header > h5 > a[data-toggle="collapse"]')
  .attr('aria-expanded', false)
$submissionForm.find('textarea[rows="10"]').prop('rows', 2)

// Create program link
$('.public-update-link').on('click', function (e) {
  e.preventDefault()
  const tempInput = document.createElement('input')
  document.body.appendChild(tempInput)
  tempInput.value = $(this).attr('href')
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)
  this.copyText = 'Copied!'
  $(this).closest('p')
    .siblings('.public-update-link-copied')
    .show()
    .delay(5000)
    .fadeOut()
})