// Detail page
$('.public-update-link').on('click', function (e) {
  e.preventDefault()
  const tempInput = document.createElement('input')
  document.body.appendChild(tempInput)
  tempInput.value = $(this).attr('href')
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)
  this.copyText = 'Copied!'
  $(this).closest('p')
    .siblings('.public-update-link-copied')
    .show()
    .delay(5000)
    .fadeOut()
})