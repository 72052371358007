initSelect2()
$('input[name="search"]').select().focus()
$('button.btn-input').on('click', function () {
  const $div = $(this).closest('div')
  const $input = $div.find('input')

  if ($(this).hasClass('btn-all')) {
    if ($(this).hasClass('btn-info')) {
      // ignore
      return
    } else {
      $div.find('.btn-info')
        .removeClass('btn-info')
        .addClass('btn-outline-info')
      $input.val('')
    }
  }

  if ($(this).hasClass('btn-info')) {
    // un-toggle it
    $(this)
      .removeClass('btn-info')
      .addClass('btn-outline-info')
    if (!$div.find('.btn-info').length) {
      $div.find('.btn-all').trigger('click')
    } else {
      const vals = $input
        .val()
        .split(',')
        .filter(v => v !== $(this).data('value'))
      $input
        .val(vals.join())
    }
  } else {
    $(this)
      .addClass('btn-info')
      .removeClass('btn-outline-info')
    const vals = $input.val().split(',')
    vals.push($(this).data('value'))
    $input.val(vals.filter(x => x).join())
  }

  // Submit form
  $(this).closest('form').trigger('submit')
})

$('input[type="checkbox"]').on('change', function () {
  $(this).closest('form').trigger('submit')
})

$('select').on('change', function () {
  $(this).closest('form').trigger('submit')
})

function initSelect2() {
  $('select.select2-enable').select2({
    width: '100%',
  })
  $('select.select2-enable').each((i, e) => {
    const element = $(e)
    const options = {}
    const control = element.select2(options)

    if (control.first().prop('multiple')) {
      control.next().on('keyup', function (e) {
        if (e.keyCode === 13) // enter
          control.select2('open')
      })
    }
  })
}